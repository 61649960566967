import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CustomModalComponent } from "../../CustomModalComponent";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import {
   createMultipleFileAsync,
   getBeneficiaryById,
   getBeneficiaryDetailsById,
   getCompanyById,
   getLastFileInFolder,
   getUrlS3,
   getYearlyParentFoldersByIds,
   updateBeneficiary,
   updateBeneficiaryDetails,
   verifyFolderByParentAndNameFolder,
} from "../../../lib/usersBEClient";
import { FormStructureComponent } from "../../FormCreation/FormStructureComponent";
import { SnackBarContext } from "../../../context/snackBarContext";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { uploadFileToS3 } from "../../../lib/s3Client";
import { FormikProps, FormikValues } from "formik";
import { ConfirmationModal } from "../../ConfirmationModal";
import { BeneficiarySheetTemplate } from "../../../lib/documentsTemplates/beneficiary/beneficiarySheetTemplate";
import { pdf } from "@react-pdf/renderer";
import { DownloadRounded } from "@mui/icons-material";
import { UserContext } from "../../../context/userContext";

export const fieldsFormat = (disable, moral) => {
   const array = [
      {
         section: moral ? "APODERADO LEGAL" : "INFORMACIÓN PERSONAL",
         fields: [
            {
               name: "name",
               title: "Nombre completo",
               type: "text",
               size: 12,
               disabled: disable,
            },
            {
               name: "informationLegalRepresentative.alias",
               title: "Alias",
               type: "text",
               size: 12,
            },
            {
               name: "informationLegalRepresentative.dateBirthday",
               title: "Fecha de nacimiento",
               type: "date",
               size: 12,
            },

            {
               name: "informationLegalRepresentative.gender",
               title: "Género",
               type: "select",
               options: ["Hombre", "Mujer", "Indefinido"],
               size: 12,
            },
            {
               name: "informationLegalRepresentative.country",
               title: "País de Origen",
               type: "select",
               options: ["Estados Unidos", "Canada", "México", "Rusia", "Chile"],
               size: 12,
            },
            {
               name: "informationLegalRepresentative.nacionality",
               title: "Nacionalidad(es)",
               type: "select",
               options: ["Estadounidense", "Mexicana", "Canadiense", "Rusa", "Chilena"],
               size: 12,
            },
            {
               name: "informationControllerBeneficiary.identificacion",
               title: "Tipo de Identificación",
               type: "select",
               options: [
                  "Credencial para votar vigente (INE)",
                  "Pasaporte vigente",
                  "Cédula profesional vigente",
                  "Credencial del Instituto Nacional de las Personas Adultas Mayores vigente",
                  "Documento migratorio vigente que corresponda (visa)",
               ],
               size: 12,
            },
            {
               depends: "informationControllerBeneficiary.identificacion",
               name: "informationControllerBeneficiary.identificationFile",
               title: "Identificación",
               type: "file",
               size: 12,
            },
            {
               name: "informationControllerBeneficiary.autorityIdentificacion",
               title: "Autoridad que la emite",
               type: "text",
               size: 12,
            },
            {
               name: "informationLegalRepresentative.curp",
               title: "CURP o equivalente en otro país",
               type: "text",
               size: 12,
            },
            {
               name: "informationLegalRepresentative.rfc",
               title: "RFC",
               type: "text",
               size: 12,
            },
            {
               name: "phoneNumber",
               title: "Número de teléfono",
               type: "text",
               size: 12,
               disabled: disable,
            },
            {
               name: "email",
               title: "Correo electrónico",
               type: "text",
               size: 12,
               disabled: disable,
            },
            {
               name: "informationLegalRepresentative.countryAddress",
               title: "País o jurisdicción de residencia para efectos fiscales",
               type: "text",
               size: 12,
            },
            {
               name: "informationLegalRepresentative.dateRip",
               title: "Fecha de defunción",
               type: "date",
               size: 12,
            },
            {
               depends: "informationLegalRepresentative.dateRip",
               name: "informationLegalRepresentative.dateRipFile",
               title: "Acta de defunción",
               type: "file",
               size: 12,
            },
         ],
      },
      {
         section: "ESTADO CIVIL",
         fields: [
            {
               name: "informationLegalRepresentative.stateCivil",
               title: "Estado civil",
               type: "select",
               options: ["Soltero", "Casado", "Divorciado", "Separación en proceso", "Viudo", "Concubinato"],
               size: 12,
            },
            {
               depends: "informationLegalRepresentative.stateCivil",
               if: ["Casado"],
               name: "informationLegalRepresentative.regimen",
               title: "Regimen",
               type: "select",
               options: ["Sociedad conyugal convencional o legal", "Separación de bienes", "Mixto"],
               size: 12,
            },
            {
               depends: "informationLegalRepresentative.stateCivil",
               name: "informationControllerBeneficiary.numberIdentificacion",
               titles: {
                  Soltero: "Numero de acta",
                  Casado: "Numero de acta",
                  Divorciado: "Numero de acta",
                  "Separación en proceso": "Número de expediente de la radicación de la demanda",
                  Viudo: "Numero de acta",
                  Concubinato: "Numero de acta",
               },
               type: "text",
               size: 12,
            },
            {
               depends: "informationLegalRepresentative.stateCivil",
               name: "informationLegalRepresentative.fileStateCivil",
               titles: {
                  Soltero: "Acta",
                  Casado: "Acta",
                  Divorciado: "Acta",
                  "Separación en proceso": "Expediente de la radicación de la demanda",
                  Viudo: "Acta",
                  Concubinato: "Acta",
               },
               type: "file",
               size: 12,
            },
            {
               name: "informationControllerBeneficiary.autorityIdentificacion",
               title: "Autoridad que la emite",
               type: "text",
               size: 12,
            },
         ],
         subSection: {
            subSectionDepends: "informationLegalRepresentative.stateCivil",
            if: ["Casado", "Concubinato"],
            title: "Datos de la identificación oficial del cónyuge, concubina o concubinario",
            subFields: [
               {
                  name: "informationConcubine.identificacion",
                  title: "Tipo de Identificación",
                  type: "select",
                  options: [
                     "Credencial para votar vigente (INE)",
                     "Pasaporte vigente",
                     "Cédula profesional vigente",
                     "Credencial del Instituto Nacional de las Personas Adultas Mayores vigente",
                     "Documento migratorio vigente que corresponda (visa)",
                  ],
                  size: 12,
               },
               {
                  depends: "informationConcubine.identificacion",
                  name: "informationConcubine.identificationFile",
                  title: "Identificación",
                  type: "file",
                  size: 12,
               },
               {
                  name: "informationConcubine.autorityIdentificacion",
                  title: "Autoridad que la emite",
                  type: "text",
                  size: 12,
               },
               {
                  name: "informationConcubine.numberIdentificacion",
                  title: "Número de identificación o Folio",
                  type: "text",
                  size: 12,
               },
            ],
         },
      },
      {
         section: "DOMICILIO",
         fields: [
            {
               name: "address.street",
               title: "Calle / Avenida / Via",
               type: "text",
               size: 12,
            },
            {
               name: "address.outdoor",
               title: "Número exterior",
               type: "text",
               size: 6,
            },
            {
               name: "address.indoor",
               title: "Número interior",
               type: "text",
               size: 6,
            },
            {
               name: "address.colonia",
               title: "Colonia",
               type: "text",
               size: 12,
            },
            {
               name: "address.municipality",
               title: "Municipio / Delegación",
               type: "text",
               size: 12,
            },
            {
               name: "address.city",
               title: "Ciudad / Población",
               type: "text",
               size: 6,
            },
            {
               name: "address.state",
               title: "Estado",
               type: "text",
               size: 6,
            },
            {
               name: "address.countryAddress",
               title: "País",
               type: "text",
               size: 6,
            },
            {
               name: "address.zipCode",
               title: "Código postal",
               type: "text",
               size: 6,
            },
            {
               name: "address.sameAddress",
               title: "¿Desea usar los mismos datos para el domicilio fiscal?",
               type: "check",
               size: 12,
               titleSize: 8,
            },
         ],
         subSection: {
            subSectionDepends: "address.sameAddress",
            if: ["No"],
            title: "Domicilio fiscal",
            subFields: [
               {
                  name: "fiscalAddress.street",
                  title: "Calle / Avenida / Via",
                  type: "text",
                  size: 12,
               },
               {
                  name: "fiscalAddress.outdoor",
                  title: "Número exterior",
                  type: "text",
                  size: 6,
               },
               {
                  name: "fiscalAddress.indoor",
                  title: "Número interior",
                  type: "text",
                  size: 6,
               },
               {
                  name: "fiscalAddress.colonia",
                  title: "Colonia",
                  type: "text",
                  size: 12,
               },
               {
                  name: "fiscalAddress.municipality",
                  title: "Municipio / Delegación",
                  type: "text",
                  size: 12,
               },
               {
                  name: "fiscalAddress.city",
                  title: "Ciudad / Población",
                  type: "text",
                  size: 6,
               },
               {
                  name: "fiscalAddress.state",
                  title: "Estado",
                  type: "text",
                  size: 6,
               },
               {
                  name: "fiscalAddress.countryAddress",
                  title: "País",
                  type: "text",
                  size: 6,
               },
               {
                  name: "fiscalAddress.zipCode",
                  title: "Código postal",
                  type: "text",
                  size: 6,
               },
            ],
         },
      },
      {
         section: "RELACIÓN CON LA PERSONA MORAL",
         fields: [
            {
               name: "relationMoralPerson.relationWithPerson",
               title: "Relación jurídica con la persona moral:",
               type: "select",
               options: ["Accionista", "Miembro del consejo de administración", "Apoderados legales", "Perfiles clave"],
               size: 12,
            },
            {
               depends: "relationMoralPerson.relationWithPerson",
               if: ["Perfiles clave"],
               name: "relationMoralPerson.relationText",
               title: "Relación",
               type: "text",
               size: 12,
            },
            {
               depends: "relationMoralPerson.relationWithPerson",
               if: ["Accionista"],
               name: "relationMoralPerson.porcentajeParticipation",
               title: "Porcentaje de participación",
               type: "text",
               size: 12,
            },
            {
               name: "relationMoralPerson.formOfParticipation",
               title: "Forma de participación o control",
               type: "select",
               options: ["Directa", "Indirecta"],
               size: 12,
            },
            {
               depends: "relationMoralPerson.relationWithPerson",
               if: ["Accionista"],
               name: "actions",
               title: "Acciones",
               type: "special",
               size: 12,
            },
            {
               depends: "relationMoralPerson.relationWithPerson",
               if: ["Accionista"],
               name: "relationMoralPerson.placeDeposited",
               title: "Lugar donde las acciones, se encuentren depositados o en custodia",
               type: "text",
               size: 12,
            },
            {
               name: "relationMoralPerson.dateInitial",
               title: "Fecha desde la cual se adquirió la condición de beneficiario controlador",
               type: "date",
               size: 12,
            },
            {
               name: "relationMoralPerson.dateFinal",
               title: "Fecha de terminación de la participación",
               type: "date",
               size: 12,
            },
         ],
      },
   ];
   if (moral) {
      array.unshift({
         section: "INFORMACIÓN GENERAL",
         fields: [
            {
               name: "businessName",
               title: "Razón o denominación social",
               type: "text",
               size: 12,
            },
            {
               name: "soportaFile",
               title: "Documento que sustenta",
               type: "file",
               size: 12,
            },
            {
               name: "businessRFC",
               title: "RFC o equivalente",
               type: "text",
               size: 12,
            },
            {
               name: "constancyFiscal",
               title: "Constancia de situación fiscal",
               type: "file",
               size: 12,
            },
            {
               name: "constitutionCountry",
               title: "País o jurisdicción de constitución o registro",
               type: "text",
               size: 12,
            },
            {
               name: "constitutionCountryFile",
               title: "Acta constitutiva",
               type: "file",
               size: 12,
            },
            {
               name: "fiscalCountry",
               title: "País o jurisdicción de residencia para efectos fiscales",
               type: "text",
               size: 12,
            },
            {
               name: "fiscalAddress",
               title: "Domicilio fiscal",
               type: "text",
               size: 12,
            },
         ],
      });
   }

   return array;
};

export const BeneficiaryControlSheetModal2 = ({ open, setOpen, beneficiaryId }) => {
   const [isLoadingInfo, setIsLoadingInfo] = useState(true);
   const [personalInfo, setPersonalInfo] = useState(null);
   const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const { governanceBody } = useContext(CorporateDataContext);
   const [disabled, setDisabled] = useState(true);
   const [reload, setReload] = useState(false);
   const formikRef = useRef<FormikProps<FormikValues>>(null);
   const [beneficiaryData, setBeneficiaryData] = useState(null);
   const [openModal, setOpenModal] = useState(false);
   const [filesToUpload, setFilesToUpload] = useState(null);
   const [foldersToUpload, setFoldersToUpload] = useState(null);
   const [society, setSociety] = useState(null);
   const [isLoadingDownload, setIsLoadingDownload] = useState(false);
   const { companySelected } = useContext(UserContext);

   const handleLoadBeneficiaryInfo = useCallback(async () => {
      setIsLoadingInfo(true);
      const [response, { beneficiary }] = await Promise.all([
         await getBeneficiaryDetailsById(beneficiaryId, companySelected._id),
         await getBeneficiaryById(beneficiaryId),
      ]);
      let companyInfo = null;
      if (beneficiary.moralAssociation) {
         companyInfo = await getBeneficiaryById(beneficiary.moralAssociation);
      } else {
         companyInfo = companySelected;
      }
      setSociety(
         beneficiary.moralAssociation ? companyInfo.beneficiary.businessName : companyInfo.person_details.businessName
      );

      if (beneficiary?.user) {
         beneficiary["name"] = beneficiary.user.firstName + " " + beneficiary.user.lastName;
         beneficiary["email"] = beneficiary.user.email;
         beneficiary["phoneNumber"] = beneficiary.user.phoneNumber;
      }
      if (beneficiary?.shareHolderType === "Persona moral") {
         const filesToGet = ["Acta constitutiva", "Constancia de situación fiscal", "Documento que soporta"];
         const filtered = beneficiary.files.filter((file) => filesToGet.includes(file.fileName));
         const yearlyFolders = await getYearlyParentFoldersByIds(filtered.map((file) => file.folderId));
         setFoldersToUpload(yearlyFolders);
         const [file1, file2, file3] = await Promise.all(
            yearlyFolders.map(async (folder) => await getLastFileInFolder(folder))
         );
         beneficiary["constitutionCountryFile"] = file1;
         beneficiary["constancyFiscal"] = file2;
         beneficiary["soportaFile"] = file3;
      }
      if (beneficiary.legalPerson === "Accionista") {
         const selectedUser = governanceBody.users.find((user) => user.user === beneficiary?.user?._id);

         const actions =
            selectedUser?.actions?.map((action) => ({
               title: action.title || "",
               nominalValue: action.votes || "",
               totalActions: action.sharesAmount || "",
            })) || [];
         beneficiary["actions"] = actions;
         if (response && !response["relationMoralPerson"]) {
            response["relationMoralPerson"] = {};
            response["relationMoralPerson"]["relationWithPerson"] = "Accionista";
         }
         if (response && response["relationMoralPerson"]) {
            response["relationMoralPerson"]["relationWithPerson"] = "Accionista";
         }
         let responseProvisional = {
            relationMoralPerson: {},
         };
         if (!response) {
            responseProvisional.relationMoralPerson["relationWithPerson"] = "Accionista";
            setPersonalInfo(responseProvisional);
            setBeneficiaryData(beneficiary);
            setIsLoadingInfo(false);
            return;
         }
      }
      setBeneficiaryData(beneficiary);
      setPersonalInfo(response);
      setIsLoadingInfo(false);
   }, [reload]);

   useEffect(() => {
      handleLoadBeneficiaryInfo();
   }, [handleLoadBeneficiaryInfo]);

   const handleSubmit = async (values) => {
      try {
         let files = [];
         let needUploadFilesMoral = false;
         setIsLoadingSubmit(true);
         if (values.informationLegalRepresentative?.dateRipFile) {
            if (values.informationLegalRepresentative?.dateRipFile?.type) {
               files.push({
                  file: values.informationLegalRepresentative?.dateRipFile,
                  name: "acta de defunción",
                  fileDirection: "informationLegalRepresentative.dateRipFile",
               });
            } else {
               values.informationLegalRepresentative.dateRipFile = undefined;
            }
         }
         if (values.informationControllerBeneficiary?.identificationFile) {
            if (values.informationControllerBeneficiary?.identificationFile?.type) {
               files.push({
                  file: values.informationControllerBeneficiary?.identificationFile,
                  name: "identificación representante",
                  fileDirection: "informationControllerBeneficiary.identificationFile",
               });
            } else {
               values.informationControllerBeneficiary.identificationFile = undefined;
            }
         }
         if (values.informationConcubine?.identificationFile) {
            if (values.informationConcubine?.identificationFile?.type) {
               files.push({
                  file: values.informationConcubine?.identificationFile,
                  name: "identificación concubin@",
                  fileDirection: "informationConcubine.identificationFile",
               });
            } else {
               values.informationConcubine.identificationFile = undefined;
            }
         }
         if (values.informationLegalRepresentative?.fileStateCivil) {
            if (values.informationLegalRepresentative?.fileStateCivil?.type) {
               files.push({
                  file: values.informationLegalRepresentative?.fileStateCivil,
                  name: "estado civil",
                  fileDirection: "informationLegalRepresentative.fileStateCivil",
               });
            } else {
               values.informationLegalRepresentative.fileStateCivil = undefined;
            }
         }
         if (files.length > 0) {
            const folder = await verifyFolderByParentAndNameFolder(beneficiaryData.folderId, "Ficha");
            const responseFile = await createMultipleFileAsync(
               files.map((file) => {
                  return {
                     name: file.name + ".pdf",
                     owner: null,
                     size: file.file.size,
                     type: file.file.type,
                     folder: folder._id,
                     fileDirection: `beneficiaries/${beneficiaryData.companyId[0]}/${folder._id}`,
                  };
               })
            );
            const s3Links = Promise.all(
               responseFile.map(async (file, index) => await uploadFileToS3(file.urlToUpload, files[index].file))
            );
            files.forEach((element, index) => {
               const split = element.fileDirection.split(".");
               values[split[0]][split[1]] = responseFile[index]["file"]._id;
            });
         }
         if (!beneficiaryData?.user || beneficiaryData.shareHolderType === "Persona moral") {
            const keys = Object.keys(values);
            const newValues = {};
            for (const key of keys) {
               if (typeof values[key] !== "object") {
                  newValues[key] = values[key];
               }
            }
            if (Object.keys(newValues).length > 0) {
               await updateBeneficiary(beneficiaryId, newValues);
            }
         }
         if (values.constitutionCountryFile || values.constancyFiscal || values.soportaFile) {
            const date = new Date();
            const dateString = date.toLocaleDateString("es-MX", {
               day: "2-digit",
               month: "long",
               year: "numeric",
               hour: "2-digit",
               minute: "2-digit",
            });
            const files = [
               {
                  fileName: "constitutionCountryFile",
                  file: values.constitutionCountryFile,
                  name: `Acta constitutiva - ${dateString}.pdf`,
               },
               {
                  fileName: "constancyFiscal",
                  file: values.constancyFiscal,
                  name: `Constancia de situación fiscal - ${dateString}.pdf`,
               },
               {
                  fileName: "soportaFile",
                  file: values.soportaFile,
                  name: `Documento que soporta - ${dateString}.pdf`,
               },
            ];
            const filtered = files.filter((filter) => !filter.file.fileDirection);
            if (filtered.length > 0) {
               setFilesToUpload(filtered);
               setOpenModal(true);
               needUploadFilesMoral = true;
            }
         }
         if (values.actions && values.actions.length !== 0) {
            values.actions = undefined;
         }
         await updateBeneficiaryDetails(beneficiaryId, companySelected._id, values);
         setIsLoadingSubmit(false);
         setDisabled(false);
         if (!needUploadFilesMoral) {
            setReload(!reload);
            showSnackBar("Información modificada", false);
         }
      } catch (error) {
         console.log(error);
         showSnackBar("Error al modificar información", true);
      }
   };

   const handleUploadFilesToExpedient = async () => {
      setIsLoadingSubmit(true);
      let order = {
         constitutionCountryFile: foldersToUpload[0],
         constancyFiscal: foldersToUpload[1],
         soportaFile: foldersToUpload[2],
      };
      const files = await createMultipleFileAsync(
         filesToUpload.map((file) => {
            return {
               name: file.name,
               owner: null,
               size: file.file.size,
               type: file.file.type,
               folder: order[file.fileName],
               beneficiary: {
                  needUpdate: false,
                  verified: undefined,
               },
               fileDirection: `beneficiaries/${beneficiaryData.companyId[0]}/${beneficiaryData.folderId}`,
            };
         })
      );
      const filesUploaded = await Promise.all(
         filesToUpload.map(async (element, index) => await uploadFileToS3(files[index].urlToUpload, element.file))
      );
      setIsLoadingSubmit(false);
      setOpenModal(false);
      setReload(!reload);
      showSnackBar("Archivos modificados correctamente.", false);
   };

   const handleCreateFile = async () => {
      setIsLoadingDownload(true);
      const logo = await getUrlS3("images-lecosy", { folder: `${companySelected._id}` }, "logo.png");

      const file = await pdf(
         <BeneficiarySheetTemplate
            structure={fieldsFormat(!!beneficiaryData?.user, beneficiaryData.shareHolderType === "Persona moral")}
            data1={personalInfo}
            data2={beneficiaryData}
            logo={logo}
            society={society}
         />
      ).toBlob();

      const url = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Ficha - ${beneficiaryData.businessName ? beneficiaryData.businessName : beneficiaryData.name}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      window.open(URL.createObjectURL(file), "_blank");
      setIsLoadingDownload(false);
   };

   return (
      <CustomModalComponent open={open} setOpen={setOpen} title={"Ficha de beneficiario controlador"}>
         {isLoadingInfo ? (
            <Stack direction={"row"} sx={{ justifyContent: "center", p: 4 }}>
               <CircularProgress />
            </Stack>
         ) : (
            <Stack>
               <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                  <Stack direction={"row"} sx={{ justifyContent: "flex-start" }}>
                     <Typography sx={{ bgcolor: "#d9d9d9", p: 2, borderTopRightRadius: 20, mt: 2 }}>
                        {society}
                     </Typography>
                  </Stack>
                  <Box>
                     <Button
                        sx={{ mx: 2, columnGap: 1, height: 35 }}
                        variant="contained"
                        onClick={handleCreateFile}
                        disabled={isLoadingDownload}
                     >
                        {isLoadingDownload ? (
                           <CircularProgress sx={{ color: "white" }} size={25} />
                        ) : (
                           <>
                              <Typography> Descargar ficha</Typography>
                              <DownloadRounded />
                           </>
                        )}
                     </Button>
                  </Box>
               </Stack>
               <FormStructureComponent
                  data={personalInfo}
                  dataSecond={beneficiaryData}
                  fieldsFormat={fieldsFormat(
                     !!beneficiaryData?.user,
                     beneficiaryData?.shareHolderType === "Persona moral"
                  )}
                  tabs
                  onSubmit={handleSubmit}
                  isLoadingSubmit={isLoadingSubmit}
                  disableEdit={disabled}
                  minHeight={440}
                  maxHeight={440}
                  maxWidth={820}
                  minWidth={800}
                  ref={formikRef}
               />
            </Stack>
         )}

         <Box
            sx={{
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               bgcolor: "#F3F3F3",
               width: "100%",
               p: 1,
               height: 50,
               borderBottomRightRadius: 5,
               borderBottomLeftRadius: 5,
            }}
         >
            <Box sx={{ width: 350 }}>
               <Button onClick={() => setDisabled(!disabled)} sx={{ height: 35, ":hover": { bgcolor: "success" } }}>
                  {!disabled ? "Cancelar modificación" : "Modificar informacion"}
               </Button>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
               <Button
                  onClick={() => {
                     setOpen(false);
                  }}
                  disabled={!disabled}
                  sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" } }}
               >
                  Cerrar
               </Button>
               <Button
                  onClick={() => {
                     if (formikRef.current) {
                        formikRef.current.submitForm();
                     }
                  }}
                  sx={{ height: 35, ":hover": { bgcolor: "success" } }}
               >
                  {isLoadingSubmit ? <CircularProgress size={25} /> : "Guardar"}
               </Button>
            </Box>
         </Box>
         <ConfirmationModal
            onConfirm={handleUploadFilesToExpedient}
            open={openModal}
            setOpen={setOpenModal}
            title="Confirmar subir archivos"
            isLoading={isLoadingSubmit}
            body={
               <Stack>
                  <Typography align="center">¿Esta seguro que desea subir los archivos?</Typography>
                  <Typography align="center" color={"red"} fontSize={12}>
                     Al confirmar, se modificara en el expediente de la persona.
                  </Typography>
               </Stack>
            }
         />
      </CustomModalComponent>
   );
};
