import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { getChildrenByGovernanceId, getFoldersById } from "../../../../lib/usersBEClient";
import { Box, Card, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { FilesContext } from "../../../../context/governanceContext/filesContext";
import dayjs from "dayjs";
import { Files } from "./Files";
import { Folder } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../../context/userContext";
import { GovernanceTheSequelContext } from "../../../../context/governanceContext/governanceTheSequelContext";

const commonFolders = [
   "Formularios y herramientas",
   "Consejos prácticos",
   "Mis archivos",
   "Compartidos conmigo",
   "Verificación de miembros",
];

export const useGetFolders = () => {
   const { personalSpace, isLoading: isLoadingDetails } = useContext(GovernanceContext);
   const [governanceFoldersTemp, setGovernanceFoldersTemp] = useState([]);
   const { startDate, endDate, confirmModal } = useContext(FilesContext);
   const { selectedFolder } = useContext(FilesContext);
   const [foldersTemp, setFoldersTemp] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   const folders = useMemo(() => {
      if (confirmModal && startDate && endDate) {
         return foldersTemp.filter(
            (file) => dayjs(new Date(file.createdAt)) >= startDate && dayjs(new Date(file.createdAt)) <= endDate
         );
      }
      return foldersTemp;
   }, [foldersTemp, confirmModal, startDate, endDate]);

   const governanceFolders = useMemo(() => {
      if (confirmModal && startDate && endDate) {
         return governanceFoldersTemp.filter(
            (file) => dayjs(new Date(file.createdAt)) >= startDate && dayjs(new Date(file.createdAt)) <= endDate
         );
      }
      return governanceFoldersTemp;
   }, [governanceFoldersTemp, confirmModal, startDate, endDate]);

   useEffect(() => {
      //PERSONAL FILES
      const fetchFolders = async () => {
         setIsLoading(true);
         const response = await getFoldersById(personalSpace.files);
         setFoldersTemp(response);
         setIsLoading(false);
      };
      //GOVERNANCEBODY
      const fetchByGovernance = async () => {
         setIsLoading(true);
         const response = await getChildrenByGovernanceId(selectedFolder._id);
         setGovernanceFoldersTemp(response.children);
         setIsLoading(false);
      };
      if (selectedFolder.title && !commonFolders.some((f) => f === selectedFolder.title)) fetchByGovernance();
      if (folders.length === 0 && personalSpace) fetchFolders();
   }, [personalSpace, isLoadingDetails, selectedFolder]);

   return { folders, governanceFolders, isLoading };
};

export const Folders = () => {
   const { id, companyId } = useParams();
   const navigate = useNavigate();
   const { folders, isLoading, governanceFolders } = useGetFolders();
   const { isCompanyAdmin } = useContext(UserContext);
   const { setDocumentSelected, consultiveGroupCompanies, consultiveGroup, companySelected, selectedGovernance } =
      useContext(GovernanceContext);
   const { selectedFolder, setSelectedFolder, setSelectedCompanyFolder, selectedCompanyFolder } =
      useContext(FilesContext);
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const inConsultiveGroup = companyId === consultiveGroup?._id;

   useEffect(() => {
      if (!selectedFolder._id) {
         if (id && selectedGovernance) setSelectedFolder({ title: selectedGovernance?.title, _id: id });
         else setSelectedFolder({ title: "Mis archivos", _id: "0" });
      }
   }, [selectedGovernance]);

   const availableCompanies = useMemo(() => {
      if (!consultiveGroup || consultiveGroup._id !== companyId || selectedCompanyFolder) return [];
      const governanceCompanyIds = new Set(
         myGovBody.filter((gb) => gb.bodiesData.title === selectedFolder.title).map((gb) => gb.bodiesData.company)
      );
      let companies = [];
      if (governanceCompanyIds.has(consultiveGroup._id)) {
         companies.push({ title: consultiveGroup?.name, _id: consultiveGroup._id });
      }
      companies = [
         ...companies,
         ...consultiveGroupCompanies
            .filter((company) => governanceCompanyIds.has(company._id))
            .map((company) => ({
               title: company.person_details.comercialName,
               _id: company._id,
            })),
      ];
      if (selectedFolder.title === "Consejo de administración" && consultiveGroup._id === companyId) {
         companies = companies.filter((company) => !consultiveGroup.companies.includes(company._id));
      }
      return companies;
   }, [consultiveGroup, consultiveGroupCompanies, selectedFolder, companyId, myGovBody]);

   if (isLoading) {
      return (
         <Box sx={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
         </Box>
      );
   }

   return (
      <Box sx={{ py: 3 }}>
         <Grid container spacing={8} rowSpacing={4}>
            {!isLoading &&
               folders?.length > 0 &&
               ["Mis archivos", "Compartidos conmigo"].includes(selectedFolder.title) &&
               folders
                  .filter((folder) =>
                     selectedFolder.title === "Compartidos conmigo"
                        ? folder.name === "Archivos compartidos"
                        : folder.name !== "Archivos compartidos"
                  )
                  .map((folder, i) => (
                     <Files key={`folder-${i}`} folder={folder} body={false} i={i} onClick={() => {}} />
                  ))}

            {!isLoading &&
               governanceFolders.length > 0 &&
               !commonFolders.includes(selectedFolder.title) &&
               (myGovBody.filter((item) => item.bodiesData.title === selectedFolder.title).length === 1 ||
                  selectedCompanyFolder) &&
               governanceFolders.map((folder, i) => (
                  <Files key={`governance-folder-${i}`} folder={folder} body={false} i={i} onClick={() => {}} />
               ))}

            {!isLoading &&
               consultiveGroupCompanies.length > 0 &&
               inConsultiveGroup &&
               myGovBody.reduce((acc, item) => (item.bodiesData.title === selectedFolder.title ? acc + 1 : acc), 0) >
                  1 &&
               !selectedCompanyFolder &&
               availableCompanies.map((company) => (
                  <Grid item xs={3} key={company._id}>
                     <Card
                        sx={{
                           px: 1,
                           py: 2,
                           borderRadius: 2,
                           cursor: "pointer",
                           "&:hover": {
                              bgcolor: "#f9f9f9",
                           },
                        }}
                        onClick={() => {
                           const governanceFound = myGovBody.find(
                              (gb) =>
                                 gb.bodiesData.company === company._id && gb.bodiesData.title === selectedFolder.title
                           );
                           setSelectedFolder({ _id: governanceFound.bodiesData._id, title: selectedFolder.title });
                           setSelectedCompanyFolder(company._id);
                        }}
                     >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                           <Folder sx={{ color: "#F1B44C", fontSize: 35 }} />
                        </Box>
                        <Stack sx={{ p: 0.5 }}>
                           <Typography fontWeight={600} noWrap>
                              {company.title}
                           </Typography>
                        </Stack>
                     </Card>
                  </Grid>
               ))}

            {isCompanyAdmin && !isLoading && selectedFolder.title === "Verificación de miembros" && (
               <>
                  <Grid item xs={3}>
                     <Card
                        sx={{
                           px: 1,
                           py: 2,
                           borderRadius: 2,
                           cursor: "pointer",
                           "&:hover": {
                              bgcolor: "#f9f9f9",
                           },
                        }}
                        onClick={() => {
                           setDocumentSelected({
                              orignalName: "Archivos por verificar",
                              name: "Archivos por verificar",
                           });
                           navigate(
                              `/gobierno-corporativo/${companySelected}/mi-cuenta/archivos/folder/archivosPorVerificar`
                           );
                        }}
                     >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                           <Folder sx={{ color: "#F1B44C", fontSize: 35 }} />
                        </Box>
                        <Stack sx={{ p: 0.5 }}>
                           <Typography fontWeight={600} noWrap>
                              Documentos de miembros
                           </Typography>
                        </Stack>
                     </Card>
                  </Grid>
                  <Grid item xs={3}>
                     <Card
                        sx={{
                           px: 1,
                           py: 2,
                           borderRadius: 2,
                           cursor: "pointer",
                           "&:hover": {
                              bgcolor: "#f9f9f9",
                           },
                        }}
                        onClick={() => {
                           setDocumentSelected({
                              orignalName: "Documentos verificados",
                              name: "Documentos verificados",
                           });
                           navigate(
                              `/gobierno-corporativo/${companySelected}/mi-cuenta/archivos/folder/documentosVerificados`
                           );
                        }}
                     >
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                           <Folder sx={{ color: "#F1B44C", fontSize: 35 }} />
                        </Box>
                        <Stack sx={{ p: 0.5 }}>
                           <Typography fontWeight={600} noWrap>
                              Documentos verificados
                           </Typography>
                        </Stack>
                     </Card>
                  </Grid>
               </>
            )}
         </Grid>
      </Box>
   );
};
